//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _getmtorderSearch,
  _getequipmentSearch,
  _getequipmentOrder,
  _getequipmentSearchId,
  _getequipmentOrderEdit,
} from '@/api/disabilities.js'
export default {
  data() {
    return {
      title: '',
      dialogVisible: false,
      options: [],
      disabled: false,
      optionsName: [],
      btnDisab: false,
      form: {
        applyStatus: '2', //申请类型
        name: '', //设备名称
        id: '',
        firm: '',
        moId: '', //工单号id
        orderId: '', //工单号的名称
        type: '', //设备类型
        exist: 0,
        money: '',
        applyOp: '',
        opComment: '',
      },
      rules: {
        moId: [{ required: true, message: '请选择工单号', trigger: 'change' }],
        name: [
          { required: true, message: '请选择设备名称', trigger: 'change' },
        ],
        firm: [{ required: true, message: '请选择设备厂商', trigger: 'blur' }],
        type: [{ required: true, message: '请输入设备型号', trigger: 'blur' }],
        money: [{ required: true, message: '请输入设备金额', trigger: 'blur' }],
        applyOp: [
          { required: true, message: '请输入申请原因', trigger: 'blur' },
        ],
      },
    }
  },
  methods: {
    open(title, data, datas) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.dialogVisible = true
      if (title == '新增') {
        {
          this.title = title
          this.options = []
          this.form = {
            moId: '', //工单号id
            orderId: '', //工单号的名称
            applyStatus: '2',
            name: '', //设备名称
            id: '', //设备id
            exist: 0,
            firm: '',
            type: '',
            money: '',
            applyOp: '',
            opComment: '',
          }
        }
      } else {
        this.title = title
        this.disabled = true
        this.remoteMethod(data)
        this.form = {
          moId: datas,
          orderId: data,
          applyStatus: '2',
          name: '',
          exist: 0,
          firm: '',
          id: '',
          type: '',
          money: '',
          applyOp: '',
          opComment: '',
        }
      }
    },
    //工单号的筛选事件
    remoteMethod(query) {
      _getmtorderSearch({ orderId: query }).then(res => {
        this.options = res.data
      })
    },
    //工单号的切换事件
    handleSelectMoid() {
      this.form.orderId = this.options.filter(item => {
        return this.form.moId == item.id
      })[0].orderId
    },
    //工单设备的筛选事件
    querySearch(queryString, cb) {
      _getequipmentSearch({ name: queryString }).then(res => {
        this.optionsName = res.data.map(item => {
          item.value = item.name + '(' + item.firm + ')'
          return {
            ...item,
          }
        })
        let newData = this.optionsName
        let results = queryString
          ? newData.filter(this.createFilter(queryString))
          : newData
        cb(results)
      })
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase()
      }
    },
    handleSelect(item) {
      this.form.name = item.name //工单设备名称
      this.form.type = item.type
      this.form.money = item.price
      this.form.firm = item.firm
      this.form.exist = 1
    },
    // 提交
    onSubmit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          console.log(this.form)

          this.btnDisab = true
          this.form.money = parseFloat(this.form.money)
          _getequipmentOrder(this.form)
            .then(res => {
              if (res.code == '1') {
                this.btnDisab = false
                if (this.title == '设备申请') {
                  this.$emit('query')
                  this.dialogVisible = false
                  this.$message(res.msg)
                } else {
                  this.$emit('query')
                  this.dialogVisible = false
                  this.$message(res.msg)
                }
              } else {
                this.btnDisab = false
              }
            })
            .catch(() => {
              this.btnDisab = false
            })
        }
      })
    },
    close() {
      this.form = {
        moId: '', //工单号id
        orderId: '', //工单号的名称
        applyStatus: '2',
        name: '', //设备名称
        id: '', //设备id
        type: '',
        exist: 0,
        money: '',
        applyOp: '',
        opComment: '',
      }
      this.options = []
      this.$refs['form'].resetFields()
    },
  },
}
